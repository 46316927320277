import Vue from "vue";
import VueRouter from "vue-router";
import HuliProjectsPage from "@/view/HuliProjectsPage.vue";
import HuliInfo from "@/view/HuliInfo.vue";
import HuliContactPage from "@/view/HuliContactPage.vue";
import NoMobile from "@/view/NoMobile.vue";

const Bve5MotorAssistance = () =>
  import(
    "@/component/Projects/Projects/software/bve5motorassistance/Bve5MotorAssistance.vue"
  );
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HuliInfo",
    meta: {
      title: "Info | Huli's site",
      keywords: "info, about me, personal",
    },

    component: HuliInfo,
  },
  {
    path: "/Info",
    name: "HuliInfo",
    meta: {
      title: "Info | Huli's site",
      keywords: "info, about me, personal",
    },
    component: HuliInfo,
  },
  {
    path: "/Projects",
    name: "HuliProjects",
    meta: {
      title: "Projects | Huli's site",
      keywords: "projects, works, portfolio",
    },
    component: HuliProjectsPage,
  },
  {
    path: "/Contact",
    name: "HuliContact",
    meta: {
      title: "Contact | Huli's site",
      keywords: "contact, reach out, email",
    },
    component: HuliContactPage,
  },
  {
    path: "/Projects/Bve5MotorAssistance",
    name: "Bve5MotorAssistance",
    component: Bve5MotorAssistance,
    meta: {
      title: "Bve5 Motor Assistance | Huli's site",
      keywords: "Bve5, motor, assistance",
    },
  },
  {
    path: "/mobile",
    name: "Mobile",
    component: NoMobile,
    meta: {
      title: "No Mobile Support",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
