<template>
    <div class="mobile-warning">
      <p>The website is temporarily unavailable for mobile access.<br><br>©Huliawsl 2025</p>

    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      // 使用箭头函数确保 this 指向 Vue 实例
      const isMobileDevice = () => {
        return (typeof window.orientation!== 'undefined') || (navigator.userAgent.indexOf('IEMobile')!== -1);
      };
  
      if (!isMobileDevice()) {
        // 如果不是移动设备，跳转到原网站（这里假设原网站是当前路径，可根据实际修改）
        this.$router.push({ name: 'HuliInfo' });
      }
    }
  };
  </script>
  
  <style scoped>
  .mobile-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1b1e21;
  }
  
  .mobile-warning p {
    display: block;
    color: white;
    text-align: center;
    /* 使用 vw 单位让字体大小自适应屏幕宽度 */
    font-size: 4vw; 
    max-width: 80%;
  }
  </style>