import Vue from 'vue'
import router from './router'
import './glbstyle.css'
import wow from 'wowjs'
import 'animate.css'
import App from './App.vue'

Vue.prototype.$wow = wow

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
  // Dynamically set the page title
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "Huli-2025"
  }

  // Default keywords that must be included on every page
  const defaultKeywords = ['Huli', '2025', 'huliawsl', 'AoXiang', 'Alden'];

  // Get keywords from the route's meta, if any
  let pageKeywords = [];
  if (to.meta.keywords) {
    // Split and trim the keywords to ensure they're clean
    pageKeywords = to.meta.keywords.split(',').map(keyword => keyword.trim());
  }

  // Combine default and page-specific keywords, removing duplicates
  const combinedKeywords = Array.from(new Set([...defaultKeywords, ...pageKeywords]));

  // Dynamically set the meta keywords
  const metaKeywords = document.querySelector('meta[name="keywords"]')
  if (metaKeywords) {
    metaKeywords.setAttribute('content', combinedKeywords.join(', '))
  } else {
    const newMeta = document.createElement('meta')
    newMeta.setAttribute('name', 'keywords')
    newMeta.setAttribute('content', combinedKeywords.join(', '))
    document.head.appendChild(newMeta)
  }

  next()
})

// Huli()

// function Huli() {
//   console.log("Hi there!")
// }