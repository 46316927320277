<template>
  <div>
    <!-- <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    > -->
    <router-view></router-view>
    <!-- </transition> -->
  </div>
</template>

<style lang="less" scoped>
.animate__animated.animate__fadeIn {
  --animate-duration: 0.2s;
}
.animate__animated.animate__fadeOut {
  --animate-duration: 0.2s;
}
div {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(18, 21, 24, 1) 0%,
    rgba(23, 27, 31, 1) 100%
  );
}
</style>

<script>
export default {
  methods: {
    // 检测是否为移动设备
    isMobileDevice() {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    },
    updatePageMeta() {
      const currentRoute = this.$router.currentRoute;
      // 设置 title
      if (currentRoute.meta.title) {
        document.title = currentRoute.meta.title;
      } else {
        document.title = "Huli-2025";
      }

      // 设置 meta keywords
      const defaultKeywords = ["Huli", "2025", "huliawsl", "AoXiang", "Alden"];
      const pageKeywords = currentRoute.meta.keywords
        ? currentRoute.meta.keywords.split(",").map((keyword) => keyword.trim())
        : [];
      const combinedKeywords = Array.from(
        new Set([...defaultKeywords, ...pageKeywords])
      );

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute("content", combinedKeywords.join(", "));
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "keywords");
        newMeta.setAttribute("content", combinedKeywords.join(", "));
        document.head.appendChild(newMeta);
      }
    },
  },
  mounted() {
    this.updatePageMeta();
    if (this.isMobileDevice()) {
      // 使用 Vue Router 的 push 方法跳转到 NoMobile 页面
      this.$router.push({ name: "Mobile" });
    }
  },
};
</script>